import { Role } from "@/acl/roles";
import { CRONOS_PRODUCTS_DETAILS } from "@/assets/constants/products/cronos";
import { i18n } from "@/main.js";

export default {
  orgName: "Cronos",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/cronos.png"),
  buttonExternalUrl: {
    getUrl: () => "https://www.cronosfinance.ch/",
    getText: () => i18n.t("wlps.cronos.buttonExternalUrlText"),
  },
  allowedLoginMethods: ["Google"],
  background: {
    path: require("@/assets/images/background/cronos.jpeg"),
    lightRatio: 0.4,
  },
  products: CRONOS_PRODUCTS_DETAILS,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["fr", "en", "de"],
  followFeatureActivated: true,
  colorScheme: [
    "#002060",
    "#0070C0",
    "#8FAADC",
    "#5096A2",
    "#ADD1D7",
    "#A6A6A6",
    "#D9D9D9",
  ],
};
