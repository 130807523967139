import { Role } from "@/acl/roles";
import { BCF_PRODUCTS_DETAILS } from "@/assets/constants/products/bcf";

export default {
  orgName: "BCF",
  showHomePageHeader: true,
  allowedLoginMethods: ["Google"],
  background: {
    path: require("@/assets/images/background/bcf.jpg"),
  },
  products: BCF_PRODUCTS_DETAILS,
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["fr", "de"],
  langInUrl: true,
  hideLoginRegisterButtons: true,
};
