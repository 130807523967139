export default {
  alquant: {
    faq: {
      howToInvest: {
        question: "How can I invest in one of Alquant's products?",
        answer:
          'As everyone\'s financial situation and risk profile are different, we need to evaluate yours and see if there is a fit with one of our products. To do this, please use <a href="/help/contact">our contact form</a> or drop us an email at <a href="mailto:info@alquant.com">info@alquant.com</a> to start a discussion or set up a call with us, we would be glad to help you.',
      },
      moreQuestions: {
        question: "I have another question, where can I ask it?",
        answer:
          'You can always contact us using <a href="/help/contact">our contact form</a> to ask any question. You can expect a response from us in the next 1-2 business days.',
      },
      whatAlquant: {
        question: "What is Alquant?",
        answer:
          'Alquant is a Swiss asset management company specialized in risk management improving traditional asset management through quantitative finance and artificial intelligence for the benefits of its clients. We are committed to delivering superior investment results and outstanding investor experience. More information can be found on our website: <a href="https://alquant.com" target="_blank">https://alquant.com</a>.',
      },
      whatDataSources: {
        question: "What data sources does Alquant use for its platform?",
        answer:
          'The data that we display mainly comes from our main data provider, <a href="https://intrinio.com" target="_blank">Intrinio</a>. For some specific data, we also use other sources, such as <a href="https://www.swissfunddata.ch/" target="_blank">Swiss Fund Data</a>, <a href="https://derivatives.juliusbaer.com/ target="_blank">Julius Baer</a>, <a href="https://keyinvest-ch-en.ubs.com/" target="_blank">UBS KeyInvest</a> and <a href="https://www.am.pictet/" target="_blank">Pictet Asset Management</a>.',
      },
      whatGoal: {
        question: "What is the goal of this platform?",
        answer:
          "As Alquant is committed to providing high transparency and an outstanding investor experience, we want our clients to have access to interactive charts with daily updated values rather than boring PDF documents generated once a month in order to see how our products are performing.",
      },
    },
    slogan: "Solutions for investors' success.",
  },
  calmgard: {
    nextSubscriptionAndRedemption: {
      nextRedemption: (ctx) =>
        `<b>Redemption</b> occurring before ${ctx.named(
          "date"
        )} at 12:00 CET will be executed at the last NAV of ${ctx.named(
          "month"
        )}.`,
      nextSubscription: (ctx) =>
        `<b>Subscription</b> occurring before ${ctx.named(
          "date"
        )} at 12:00 CET will be executed at the last NAV of ${ctx.named(
          "month"
        )}.`,
      title: "Next possible Trading Dates",
    },
  },
  cronos: {
    buttonExternalUrlText: "Home page Cronos",
  },
  demo: {
    customSections: {
      useCases: {
        title: "Use cases",
        alpinum: {
          strategyName: "Alpinum",
          title: "Alpinum",
          historicalResults: {
            title: "Historical results",
            biggestDrawdowns: {
              title: (ctx) => `Biggest drawdowns of ${ctx.named("benchmark")}`,
            },
            drawdowns: {
              title: "Drawdowns",
            },
          },
          metaInfo: {
            title: "Use Case - Alpinum",
          },
          parameters: {
            runButton: "Run use case",
            benchmark: "Benchmark",
            title: "Parameters",
          },
        },
      },
    },
  },
  falgom: {
    taroDiversified: {
      useCase: {
        allocation: {
          equity: "Equity portfolio",
          product: "Allocation to TARO® Diversified",
          title: "Portfolio allocation",
        },
        drawdowns: {
          title: "Drawdowns",
          biggestDrawdowns: "Biggest drawdowns",
        },
        historicalResults: {
          title: "Historical results",
        },
        metaInfo: {
          title: "Use Case - TARO® Diversified",
        },
      },
    },
  },
  mirante: {
    buttonExternalUrlText: "Home page MFM",
  },
  ppt: {
    buttonExternalUrl: "https://ppt.ch/en/asset-management/",
    buttonExternalUrlText: "Back to main site",
  },
};
