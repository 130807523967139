import { cloneDeep } from "lodash";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { PerformanceStatistic } from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];

// They asked us to remove the skewness on all their products.
const PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS = [
  PerformanceStatistic.cumulativeReturn,
  PerformanceStatistic.annualizedReturn,
  PerformanceStatistic.annualizedVolatility,
  PerformanceStatistic.maximumDrawdown,
  PerformanceStatistic.sharpeRatio,
];

// ******---------- MFM GLOBAL CONVERTIBLE DEFENSIVE ----------*****
const mfmGlobalConvertibleDefensiveIChf = {
  productId: "mfm-global-convertible-defensive-i-chf",
  storeModule: "mfmGlobalConvertibleDefensiveIChf",
  productName: "MFM Global Convertible Defensive I CHF",
  productShortName: "MFM Global Convertible Defensive",
  productColor: ORG_COLOR,
  productShareClassRoot: "mfm-global-convertible-defensive",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const mfmGlobalConvertibleDefensiveIEur = cloneDeep(
  mfmGlobalConvertibleDefensiveIChf
);
mfmGlobalConvertibleDefensiveIEur.productId =
  "mfm-global-convertible-defensive-i-eur";
mfmGlobalConvertibleDefensiveIEur.storeModule =
  "mfmGlobalConvertibleDefensiveIEur";
mfmGlobalConvertibleDefensiveIEur.productName =
  "MFM Global Convertible Defensive I EUR";
mfmGlobalConvertibleDefensiveIEur.isDefaultShareClass = false;

const mfmGlobalConvertibleDefensiveIUsd = cloneDeep(
  mfmGlobalConvertibleDefensiveIChf
);
mfmGlobalConvertibleDefensiveIUsd.productId =
  "mfm-global-convertible-defensive-i-usd";
mfmGlobalConvertibleDefensiveIUsd.storeModule =
  "mfmGlobalConvertibleDefensiveIUsd";
mfmGlobalConvertibleDefensiveIUsd.productName =
  "MFM Global Convertible Defensive I USD";
mfmGlobalConvertibleDefensiveIUsd.isDefaultShareClass = false;

const mfmGlobalConvertibleDefensiveRChf = cloneDeep(
  mfmGlobalConvertibleDefensiveIChf
);
mfmGlobalConvertibleDefensiveRChf.productId =
  "mfm-global-convertible-defensive-r-chf";
mfmGlobalConvertibleDefensiveRChf.storeModule =
  "mfmGlobalConvertibleDefensiveRChf";
mfmGlobalConvertibleDefensiveRChf.productName =
  "MFM Global Convertible Defensive R CHF";
mfmGlobalConvertibleDefensiveRChf.isDefaultShareClass = false;

const mfmGlobalConvertibleDefensiveREur = cloneDeep(
  mfmGlobalConvertibleDefensiveIChf
);
mfmGlobalConvertibleDefensiveREur.productId =
  "mfm-global-convertible-defensive-r-eur";
mfmGlobalConvertibleDefensiveREur.storeModule =
  "mfmGlobalConvertibleDefensiveREur";
mfmGlobalConvertibleDefensiveREur.productName =
  "MFM Global Convertible Defensive R EUR";
mfmGlobalConvertibleDefensiveREur.isDefaultShareClass = false;

const mfmGlobalConvertibleDefensiveRUsd = cloneDeep(
  mfmGlobalConvertibleDefensiveIChf
);
mfmGlobalConvertibleDefensiveRUsd.productId =
  "mfm-global-convertible-defensive-r-usd";
mfmGlobalConvertibleDefensiveRUsd.storeModule =
  "mfmGlobalConvertibleDefensiveRUsd";
mfmGlobalConvertibleDefensiveRUsd.productName =
  "MFM Global Convertible Defensive R USD";
mfmGlobalConvertibleDefensiveRUsd.isDefaultShareClass = false;

const mfmGlobalConvertibleDefensiveRfChf = cloneDeep(
  mfmGlobalConvertibleDefensiveIChf
);
mfmGlobalConvertibleDefensiveRfChf.productId =
  "mfm-global-convertible-defensive-rf-chf";
mfmGlobalConvertibleDefensiveRfChf.storeModule =
  "mfmGlobalConvertibleDefensiveRfChf";
mfmGlobalConvertibleDefensiveRfChf.productName =
  "MFM Global Convertible Defensive RF CHF";
mfmGlobalConvertibleDefensiveRfChf.isDefaultShareClass = false;

const mfmGlobalConvertibleDefensiveZChf = cloneDeep(
  mfmGlobalConvertibleDefensiveIChf
);
mfmGlobalConvertibleDefensiveZChf.productId =
  "mfm-global-convertible-defensive-z-chf";
mfmGlobalConvertibleDefensiveZChf.storeModule =
  "mfmGlobalConvertibleDefensiveZChf";
mfmGlobalConvertibleDefensiveZChf.productName =
  "MFM Global Convertible Defensive Z CHF";
mfmGlobalConvertibleDefensiveZChf.isDefaultShareClass = false;

// ******---------- MFM GLOBAL CONVERTIBLE OPPORTUNITIES ----------*****
const mfmGlobalConvertibleOpportunitiesIEur = {
  productId: "mfm-global-convertible-opportunities-i-eur",
  storeModule: "mfmGlobalConvertibleOpportunitiesIEur",
  productName: "MFM Global Convertible Opportunities I EUR",
  productShortName: "MFM Global Convertible Opportunities",
  productColor: ORG_COLOR,
  productShareClassRoot: "mfm-global-convertible-opportunities",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const mfmGlobalConvertibleOpportunitiesIChf = cloneDeep(
  mfmGlobalConvertibleOpportunitiesIEur
);
mfmGlobalConvertibleOpportunitiesIChf.productId =
  "mfm-global-convertible-opportunities-i-chf";
mfmGlobalConvertibleOpportunitiesIChf.storeModule =
  "mfmGlobalConvertibleOpportunitiesIChf";
mfmGlobalConvertibleOpportunitiesIChf.productName =
  "MFM Global Convertible Opportunities I CHF";
mfmGlobalConvertibleOpportunitiesIChf.isDefaultShareClass = false;

const mfmGlobalConvertibleOpportunitiesIUsd = cloneDeep(
  mfmGlobalConvertibleOpportunitiesIEur
);
mfmGlobalConvertibleOpportunitiesIUsd.productId =
  "mfm-global-convertible-opportunities-i-usd";
mfmGlobalConvertibleOpportunitiesIUsd.storeModule =
  "mfmGlobalConvertibleOpportunitiesIUsd";
mfmGlobalConvertibleOpportunitiesIUsd.productName =
  "MFM Global Convertible Opportunities I USD";
mfmGlobalConvertibleOpportunitiesIUsd.isDefaultShareClass = false;

const mfmGlobalConvertibleOpportunitiesRChf = cloneDeep(
  mfmGlobalConvertibleOpportunitiesIEur
);
mfmGlobalConvertibleOpportunitiesRChf.productId =
  "mfm-global-convertible-opportunities-r-chf";
mfmGlobalConvertibleOpportunitiesRChf.storeModule =
  "mfmGlobalConvertibleOpportunitiesRChf";
mfmGlobalConvertibleOpportunitiesRChf.productName =
  "MFM Global Convertible Opportunities R CHF";
mfmGlobalConvertibleOpportunitiesRChf.isDefaultShareClass = false;

const mfmGlobalConvertibleOpportunitiesREur = cloneDeep(
  mfmGlobalConvertibleOpportunitiesIEur
);
mfmGlobalConvertibleOpportunitiesREur.productId =
  "mfm-global-convertible-opportunities-r-eur";
mfmGlobalConvertibleOpportunitiesREur.storeModule =
  "mfmGlobalConvertibleOpportunitiesREur";
mfmGlobalConvertibleOpportunitiesREur.productName =
  "MFM Global Convertible Opportunities R EUR";
mfmGlobalConvertibleOpportunitiesREur.isDefaultShareClass = false;

const mfmGlobalConvertibleOpportunitiesRUsd = cloneDeep(
  mfmGlobalConvertibleOpportunitiesIEur
);
mfmGlobalConvertibleOpportunitiesRUsd.productId =
  "mfm-global-convertible-opportunities-r-usd";
mfmGlobalConvertibleOpportunitiesRUsd.storeModule =
  "mfmGlobalConvertibleOpportunitiesRUsd";
mfmGlobalConvertibleOpportunitiesRUsd.productName =
  "MFM Global Convertible Opportunities R USD";
mfmGlobalConvertibleOpportunitiesRUsd.isDefaultShareClass = false;

const mfmGlobalConvertibleOpportunitiesZChf = cloneDeep(
  mfmGlobalConvertibleOpportunitiesIEur
);
mfmGlobalConvertibleOpportunitiesZChf.productId =
  "mfm-global-convertible-opportunities-z-chf";
mfmGlobalConvertibleOpportunitiesZChf.storeModule =
  "mfmGlobalConvertibleOpportunitiesZChf";
mfmGlobalConvertibleOpportunitiesZChf.productName =
  "MFM Global Convertible Opportunities Z CHF";
mfmGlobalConvertibleOpportunitiesZChf.isDefaultShareClass = false;

// ******---------- MFM GREEN AND SOCIAL BONDS ----------*****
const mfmGreenAndSocialBondsIChf = {
  productId: "mfm-green-and-social-bonds-i-chf",
  storeModule: "mfmGreenAndSocialBondsIChf",
  productName: "MFM Green and Social Bonds I CHF",
  productShortName: "MFM Green and Social Bonds",
  productColor: ORG_COLOR,
  productShareClassRoot: "mfm-green-and-social-bonds",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const mfmGreenAndSocialBondsIEur = cloneDeep(mfmGreenAndSocialBondsIChf);
mfmGreenAndSocialBondsIEur.productId = "mfm-green-and-social-bonds-i-eur";
mfmGreenAndSocialBondsIEur.storeModule = "mfmGreenAndSocialBondsIEur";
mfmGreenAndSocialBondsIEur.productName = "MFM Green and Social Bonds I EUR";
mfmGreenAndSocialBondsIEur.isDefaultShareClass = false;

const mfmGreenAndSocialBondsPChf = cloneDeep(mfmGreenAndSocialBondsIChf);
mfmGreenAndSocialBondsPChf.productId = "mfm-green-and-social-bonds-p-chf";
mfmGreenAndSocialBondsPChf.storeModule = "mfmGreenAndSocialBondsPChf";
mfmGreenAndSocialBondsPChf.productName = "MFM Green and Social Bonds P CHF";
mfmGreenAndSocialBondsPChf.isDefaultShareClass = false;

const mfmGreenAndSocialBondsRChf = cloneDeep(mfmGreenAndSocialBondsIChf);
mfmGreenAndSocialBondsRChf.productId = "mfm-green-and-social-bonds-r-chf";
mfmGreenAndSocialBondsRChf.storeModule = "mfmGreenAndSocialBondsRChf";
mfmGreenAndSocialBondsRChf.productName = "MFM Green and Social Bonds R CHF";
mfmGreenAndSocialBondsRChf.isDefaultShareClass = false;

const mfmGreenAndSocialBondsSChf = cloneDeep(mfmGreenAndSocialBondsIChf);
mfmGreenAndSocialBondsSChf.productId = "mfm-green-and-social-bonds-s-chf";
mfmGreenAndSocialBondsSChf.storeModule = "mfmGreenAndSocialBondsSChf";
mfmGreenAndSocialBondsSChf.productName = "MFM Green and Social Bonds S CHF";
mfmGreenAndSocialBondsSChf.isDefaultShareClass = false;

const mfmNorthlightEuropeanCreditOpportunitiesIEur = {
  productId: "mfm-northlight-european-credit-opportunities-i-eur",
  storeModule: "mfmNorthlightEuropeanCreditOpportunitiesIEur",
  productName: "MFM Northlight European Credit Opportunities I EUR",
  productShortName: "MFM Northlight European Credit Opportunities",
  productColor: ORG_COLOR,
  productShareClassRoot: "mfm-northlight-european-credit-opportunities",
  isDefaultShareClass: true,
  showLastPrice: true,
  availableLanguages: ["en"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const mfmNorthlightEuropeanCreditOpportunitiesREur = cloneDeep(
  mfmNorthlightEuropeanCreditOpportunitiesIEur
);
mfmNorthlightEuropeanCreditOpportunitiesREur.productId =
  "mfm-northlight-european-credit-opportunities-r-eur";
mfmNorthlightEuropeanCreditOpportunitiesREur.storeModule =
  "mfmNorthlightEuropeanCreditOpportunitiesREur";
mfmNorthlightEuropeanCreditOpportunitiesREur.productName =
  "MFM Northlight European Credit Opportunities R EUR";
mfmNorthlightEuropeanCreditOpportunitiesREur.isDefaultShareClass = false;

const mfmNorthlightEuropeanCreditOpportunitiesRpEur = cloneDeep(
  mfmNorthlightEuropeanCreditOpportunitiesIEur
);
mfmNorthlightEuropeanCreditOpportunitiesRpEur.productId =
  "mfm-northlight-european-credit-opportunities-rp-eur";
mfmNorthlightEuropeanCreditOpportunitiesRpEur.storeModule =
  "mfmNorthlightEuropeanCreditOpportunitiesRpEur";
mfmNorthlightEuropeanCreditOpportunitiesRpEur.productName =
  "MFM Northlight European Credit Opportunities RP EUR";
mfmNorthlightEuropeanCreditOpportunitiesRpEur.isDefaultShareClass = false;

const mfmNorthlightEuropeanCreditOpportunitiesIChf = cloneDeep(
  mfmNorthlightEuropeanCreditOpportunitiesIEur
);
mfmNorthlightEuropeanCreditOpportunitiesIChf.productId =
  "mfm-northlight-european-credit-opportunities-i-chf";
mfmNorthlightEuropeanCreditOpportunitiesIChf.storeModule =
  "mfmNorthlightEuropeanCreditOpportunitiesIChf";
mfmNorthlightEuropeanCreditOpportunitiesIChf.productName =
  "MFM Northlight European Credit Opportunities I CHF";
mfmNorthlightEuropeanCreditOpportunitiesIChf.isDefaultShareClass = false;

const mfmNorthlightEuropeanCreditOpportunitiesRChf = cloneDeep(
  mfmNorthlightEuropeanCreditOpportunitiesIEur
);
mfmNorthlightEuropeanCreditOpportunitiesRChf.productId =
  "mfm-northlight-european-credit-opportunities-r-chf";
mfmNorthlightEuropeanCreditOpportunitiesRChf.storeModule =
  "mfmNorthlightEuropeanCreditOpportunitiesRChf";
mfmNorthlightEuropeanCreditOpportunitiesRChf.productName =
  "MFM Northlight European Credit Opportunities R CHF";
mfmNorthlightEuropeanCreditOpportunitiesRChf.isDefaultShareClass = false;

const mfmNorthlightEuropeanCreditOpportunitiesIUsd = cloneDeep(
  mfmNorthlightEuropeanCreditOpportunitiesIEur
);
mfmNorthlightEuropeanCreditOpportunitiesIUsd.productId =
  "mfm-northlight-european-credit-opportunities-i-usd";
mfmNorthlightEuropeanCreditOpportunitiesIUsd.storeModule =
  "mfmNorthlightEuropeanCreditOpportunitiesIUsd";
mfmNorthlightEuropeanCreditOpportunitiesIUsd.productName =
  "MFM Northlight European Credit Opportunities I USD";
mfmNorthlightEuropeanCreditOpportunitiesIUsd.isDefaultShareClass = false;

const mfmNorthlightEuropeanCreditOpportunitiesRUsd = cloneDeep(
  mfmNorthlightEuropeanCreditOpportunitiesIEur
);
mfmNorthlightEuropeanCreditOpportunitiesRUsd.productId =
  "mfm-northlight-european-credit-opportunities-r-usd";
mfmNorthlightEuropeanCreditOpportunitiesRUsd.storeModule =
  "mfmNorthlightEuropeanCreditOpportunitiesRUsd";
mfmNorthlightEuropeanCreditOpportunitiesRUsd.productName =
  "MFM Northlight European Credit Opportunities R USD";
mfmNorthlightEuropeanCreditOpportunitiesRUsd.isDefaultShareClass = false;

const mfmNorthlightEuropeanCreditOpportunitiesIGbp = cloneDeep(
  mfmNorthlightEuropeanCreditOpportunitiesIEur
);
mfmNorthlightEuropeanCreditOpportunitiesIGbp.productId =
  "mfm-northlight-european-credit-opportunities-i-gbp";
mfmNorthlightEuropeanCreditOpportunitiesIGbp.storeModule =
  "mfmNorthlightEuropeanCreditOpportunitiesIGbp";
mfmNorthlightEuropeanCreditOpportunitiesIGbp.productName =
  "MFM Northlight European Credit Opportunities I GBP";
mfmNorthlightEuropeanCreditOpportunitiesIGbp.isDefaultShareClass = false;

const mfmAssetAllocationAdagioRfChf = {
  productId: "mfm-asset-allocation-adagio-rf-chf",
  storeModule: "mfmAssetAllocationAdagioRfChf",
  productName: "MFM Asset Allocation Adagio RF CHF",
  productShortName: "MFM Asset Allocation Adagio",
  productColor: ORG_COLOR,
  productShareClassRoot: "mfm-asset-allocation-adagio",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const mfmAssetAllocationAdagioPChf = {
  productId: "mfm-asset-allocation-adagio-p-chf",
  storeModule: "mfmAssetAllocationAdagioPChf",
  productName: "MFM Asset Allocation Adagio P CHF",
  productShortName: "MFM Asset Allocation Adagio",
  productColor: ORG_COLOR,
  productShareClassRoot: "mfm-asset-allocation-adagio",
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const mfmAssetAllocationAndanteRfChf = {
  productId: "mfm-asset-allocation-andante-rf-chf",
  storeModule: "mfmAssetAllocationAndanteRfChf",
  productName: "MFM Asset Allocation Andante RF CHF",
  productShortName: "MFM Asset Allocation Andante",
  productColor: ORG_COLOR,
  productShareClassRoot: "mfm-asset-allocation-andante",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const mfmAssetAllocationAndantePChf = {
  productId: "mfm-asset-allocation-andante-p-chf",
  storeModule: "mfmAssetAllocationAndantePChf",
  productName: "MFM Asset Allocation Andante P CHF",
  productShortName: "MFM Asset Allocation Andante",
  productColor: ORG_COLOR,
  productShareClassRoot: "mfm-asset-allocation-andante",
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const mfmAssetAllocationAllegroRfChf = {
  productId: "mfm-asset-allocation-allegro-rf-chf",
  storeModule: "mfmAssetAllocationAllegroRfChf",
  productName: "MFM Asset Allocation Allegro RF CHF",
  productShortName: "MFM Asset Allocation Allegro",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const mfmGlobalEquityRfChf = {
  productId: "mfm-global-equity-rf-chf",
  storeModule: "mfmGlobalEquityRfChf",
  productName: "MFM Global Equity RF CHF",
  productShortName: "MFM Global Equity",
  productColor: ORG_COLOR,
  productShareClassRoot: "mfm-global-equity",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const mfmGlobalEquityPChf = {
  productId: "mfm-global-equity-p-chf",
  storeModule: "mfmGlobalEquityPChf",
  productName: "MFM Global Equity P CHF",
  productShortName: "MFM Global Equity",
  productColor: ORG_COLOR,
  productShareClassRoot: "mfm-global-equity",
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const mfmGlobalEquityRfEur = {
  productId: "mfm-global-equity-rf-eur",
  storeModule: "mfmGlobalEquityRfEur",
  productName: "MFM Global Equity RF EUR",
  productShortName: "MFM Global Equity",
  productColor: ORG_COLOR,
  productShareClassRoot: "mfm-global-equity",
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const mfmGlobalFixedIncomeRfChf = {
  productId: "mfm-global-fixed-income-rf-chf",
  storeModule: "mfmGlobalFixedIncomeRfChf",
  productName: "MFM Global Fixed Income RF CHF",
  productShortName: "MFM Global Fixed Income",
  productColor: ORG_COLOR,
  productShareClassRoot: "mfm-global-fixed-income",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const mfmGlobalFixedIncomePChf = {
  productId: "mfm-global-fixed-income-p-chf",
  storeModule: "mfmGlobalFixedIncomePChf",
  productName: "MFM Global Fixed Income P CHF",
  productShortName: "MFM Global Fixed Income",
  productColor: ORG_COLOR,
  productShareClassRoot: "mfm-global-fixed-income",
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const mfmGlobalRealEstateRfChf = {
  productId: "mfm-global-real-estate-rf-chf",
  storeModule: "mfmGlobalRealEstateRfChf",
  productName: "MFM Global Real Estate RF CHF",
  productShortName: "MFM Global Real Estate",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const mfmGlobalAlternativeStrategiesRfChf = {
  productId: "mfm-global-alternative-strategies-rf-chf",
  storeModule: "mfmGlobalAlternativeStrategiesRfChf",
  productName: "MFM Global Alternative Strategies RF CHF",
  productShortName: "MFM Global Alternative Strategies",
  productColor: ORG_COLOR,
  productShareClassRoot: "mfm-global-alternative-strategies",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const mfmGlobalAlternativeStrategiesPChf = {
  productId: "mfm-global-alternative-strategies-p-chf",
  storeModule: "mfmGlobalAlternativeStrategiesPChf",
  productName: "MFM Global Alternative Strategies P CHF",
  productShortName: "MFM Global Alternative Strategies",
  productColor: ORG_COLOR,
  productShareClassRoot: "mfm-global-alternative-strategies",
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: PERF_STATS_WITHOUT_BENCHMARK_AND_WITHOUT_SKEWNESS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

export const MIRANTE_PRODUCTS_DETAILS = [
  mfmGlobalConvertibleDefensiveIChf,
  mfmGlobalConvertibleDefensiveIEur,
  mfmGlobalConvertibleDefensiveIUsd,
  mfmGlobalConvertibleDefensiveRChf,
  mfmGlobalConvertibleDefensiveREur,
  mfmGlobalConvertibleDefensiveRUsd,
  mfmGlobalConvertibleDefensiveRfChf,
  mfmGlobalConvertibleDefensiveZChf,
  mfmGlobalConvertibleOpportunitiesIChf,
  mfmGlobalConvertibleOpportunitiesIEur,
  mfmGlobalConvertibleOpportunitiesIUsd,
  mfmGlobalConvertibleOpportunitiesRChf,
  mfmGlobalConvertibleOpportunitiesREur,
  mfmGlobalConvertibleOpportunitiesRUsd,
  mfmGlobalConvertibleOpportunitiesZChf,
  mfmGreenAndSocialBondsIChf,
  mfmGreenAndSocialBondsIEur,
  mfmGreenAndSocialBondsPChf,
  mfmGreenAndSocialBondsRChf,
  mfmGreenAndSocialBondsSChf,
  mfmNorthlightEuropeanCreditOpportunitiesIEur,
  mfmNorthlightEuropeanCreditOpportunitiesREur,
  mfmNorthlightEuropeanCreditOpportunitiesRpEur,
  mfmNorthlightEuropeanCreditOpportunitiesIChf,
  mfmNorthlightEuropeanCreditOpportunitiesRChf,
  mfmNorthlightEuropeanCreditOpportunitiesIUsd,
  mfmNorthlightEuropeanCreditOpportunitiesRUsd,
  mfmNorthlightEuropeanCreditOpportunitiesIGbp,
  mfmAssetAllocationAdagioRfChf,
  mfmAssetAllocationAdagioPChf,
  mfmAssetAllocationAndanteRfChf,
  mfmAssetAllocationAndantePChf,
  mfmAssetAllocationAllegroRfChf,
  mfmGlobalEquityRfChf,
  mfmGlobalEquityPChf,
  mfmGlobalEquityRfEur,
  mfmGlobalFixedIncomeRfChf,
  mfmGlobalFixedIncomePChf,
  mfmGlobalRealEstateRfChf,
  mfmGlobalAlternativeStrategiesRfChf,
  mfmGlobalAlternativeStrategiesPChf,
];

export const MIRANTE_PRODUCTS = MIRANTE_PRODUCTS_DETAILS.map(
  (details) => details.storeModule
);
