import Vue from "vue";
import moment from "moment";

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  const arr = value.split(" ");
  const capitalizedArray = [];
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalizedArray.push(capitalized);
  });
  return capitalizedArray.join(" ");
});

Vue.filter("title", function (value, replacer = "_") {
  if (!value) return "";
  value = value.toString();

  const arr = value.split(replacer);
  const capitalizedArray = [];
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalizedArray.push(capitalized);
  });
  return capitalizedArray.join(" ");
});

Vue.filter("truncate", function (value, limit) {
  return value.substring(0, limit);
});

Vue.filter("tailing", function (value, tail) {
  return value + tail;
});

Vue.filter("time", function (value, is24HrFormat = false) {
  if (value) {
    const date = new Date(Date.parse(value));
    let hours = date.getHours();
    const min = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
    if (!is24HrFormat) {
      const time = hours > 12 ? "AM" : "PM";
      hours = hours % 12 || 12;
      return `${hours}:${min} ${time}`;
    }
    return `${hours}:${min}`;
  }
});

Vue.filter("date", function (value, format = "DD-MM-YYYY") {
  return moment(value).format(format);
});

Vue.filter("month", function (val, showYear = true) {
  val = String(val);

  const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
  if (!showYear) {
    return regx.exec(val)[1];
  } else {
    return `${regx.exec(val)[1]} ${regx.exec(val)[2]}`;
  }
});

Vue.filter("csv", function (value) {
  return value.join(", ");
});

Vue.filter("filter_tags", function (value) {
  return value.replace(/<\/?[^>]+(>|$)/g, "");
});

Vue.filter("k_formatter", function (num) {
  return num > 999 ? `${(num / 1000).toFixed(1)}k` : num;
});

Vue.filter("percentage", function (value, decimals) {
  if (!value) {
    value = 0;
  }

  if (!decimals) {
    decimals = 0;
  }

  value = value * 100;
  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  value = value + "%";
  return value;
});
