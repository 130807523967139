import _ from "lodash";

import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import {
  STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
  PerformanceStatistic,
} from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];

// ******---------- BCF Funds Equity Switzerland ----------*****
const EQUITY_SWITZERLAND_PERF_STATS = [
  PerformanceStatistic.cumulativeReturn,
  PerformanceStatistic.annualizedReturn,
  PerformanceStatistic.annualizedVolatility,
  PerformanceStatistic.maximumDrawdown,
  PerformanceStatistic.sharpeRatio,
  PerformanceStatistic.skewness,
  PerformanceStatistic.beta,
  PerformanceStatistic.trackingError,
  PerformanceStatistic.correlation,
];

const bcfFundsEquitySwitzerlandA = {
  productId: "bcf-funds-equity-switzerland-a",
  storeModule: "bcfFundsEquitySwitzerlandA",
  productName: "Equity Switzerland A",
  productShortName: "Equity Switzerland",
  productColor: ORG_COLOR,
  productShareClassRoot: "bcf-funds-equity-switzerland",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.outperformance,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: EQUITY_SWITZERLAND_PERF_STATS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const bcfFundsEquitySwitzerlandI = _.cloneDeep(bcfFundsEquitySwitzerlandA);
bcfFundsEquitySwitzerlandI.productId = "bcf-funds-equity-switzerland-i";
bcfFundsEquitySwitzerlandI.storeModule = "bcfFundsEquitySwitzerlandI";
bcfFundsEquitySwitzerlandI.productName = "Equity Switzerland I";
bcfFundsEquitySwitzerlandI.isDefaultShareClass = false;

const bcfFundsEquitySwitzerlandM = _.cloneDeep(bcfFundsEquitySwitzerlandA);
bcfFundsEquitySwitzerlandM.productId = "bcf-funds-equity-switzerland-m";
bcfFundsEquitySwitzerlandM.storeModule = "bcfFundsEquitySwitzerlandM";
bcfFundsEquitySwitzerlandM.productName = "Equity Switzerland M";
bcfFundsEquitySwitzerlandM.isDefaultShareClass = false;

// ******---------- BCF Funds Active Balanced ----------*****
const bcfFundsActiveBalancedAp = {
  productId: "bcf-funds-active-balanced-ap",
  storeModule: "bcfFundsActiveBalancedAp",
  productName: "Active Balanced AP",
  productShortName: "Active Balanced",
  productColor: ORG_COLOR,
  productShareClassRoot: "bcf-funds-active-balanced",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.outperformance,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const bcfFundsActiveBalancedA = _.cloneDeep(bcfFundsActiveBalancedAp);
bcfFundsActiveBalancedA.productId = "bcf-funds-active-balanced-a";
bcfFundsActiveBalancedA.storeModule = "bcfFundsActiveBalancedA";
bcfFundsActiveBalancedA.productName = "Active Balanced A";
bcfFundsActiveBalancedA.isDefaultShareClass = false;

// ******---------- BCF Funds Active Dynamic ----------*****
const bcfFundsActiveDynamicAp = {
  productId: "bcf-funds-active-dynamic-ap",
  storeModule: "bcfFundsActiveDynamicAp",
  productName: "Active Dynamic AP",
  productShortName: "Active Dynamic",
  productColor: ORG_COLOR,
  productShareClassRoot: "bcf-funds-active-dynamic",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.outperformance,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const bcfFundsActiveDynamicA = _.cloneDeep(bcfFundsActiveDynamicAp);
bcfFundsActiveDynamicA.productId = "bcf-funds-active-dynamic-a";
bcfFundsActiveDynamicA.storeModule = "bcfFundsActiveDynamicA";
bcfFundsActiveDynamicA.productName = "Active Dynamic A";
bcfFundsActiveDynamicA.isDefaultShareClass = false;

// ******---------- BCF Funds Active Yield ----------*****
const bcfFundsActiveYieldAp = {
  productId: "bcf-funds-active-yield-ap",
  storeModule: "bcfFundsActiveYieldAp",
  productName: "Active Yield AP",
  productShortName: "Active Yield",
  productColor: ORG_COLOR,
  productShareClassRoot: "bcf-funds-active-yield",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.outperformance,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const bcfFundsActiveYieldA = _.cloneDeep(bcfFundsActiveYieldAp);
bcfFundsActiveYieldA.productId = "bcf-funds-active-yield-a";
bcfFundsActiveYieldA.storeModule = "bcfFundsActiveYieldA";
bcfFundsActiveYieldA.productName = "Active Yield A";
bcfFundsActiveYieldA.isDefaultShareClass = false;

export const BCF_PRODUCTS_DETAILS = [
  bcfFundsEquitySwitzerlandA,
  bcfFundsEquitySwitzerlandI,
  bcfFundsEquitySwitzerlandM,
  bcfFundsActiveBalancedAp,
  bcfFundsActiveBalancedA,
  bcfFundsActiveDynamicAp,
  bcfFundsActiveDynamicA,
  bcfFundsActiveYieldAp,
  bcfFundsActiveYieldA,
];

export const BCF_PRODUCTS = BCF_PRODUCTS_DETAILS.map(
  (details) => details.storeModule
);
