import { FALGOM_EVENTS } from "@/assets/constants/eventsDates";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { PerformanceStatistic } from "@/assets/constants/perfStats";
import { ZoomButton } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];

const taroDiversified = {
  productId: "taro-diversified",
  storeModule: "taroDiversified",
  productName: "TARO® Diversified",
  productColor: ORG_COLOR,
  oldDataEndDate: "2022-01-31",
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: [
      ZoomButton.sixMonths,
      ZoomButton.ytd,
      ZoomButton.threeYears,
      ZoomButton.fiveYears,
      ZoomButton.fullPeriod,
    ],
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.drawdowns,
      GenericProductCards.returnProfile,
    ],
    perfStats: [
      PerformanceStatistic.cumulativeReturn,
      PerformanceStatistic.annualizedReturn,
      PerformanceStatistic.annualizedVolatility,
      PerformanceStatistic.maximumDrawdown,
      PerformanceStatistic.sharpeRatio,
      PerformanceStatistic.skewness,
      PerformanceStatistic.kurtosis,
      PerformanceStatistic.sortinoRatio,
      PerformanceStatistic.calmarRatio,
      PerformanceStatistic.omegaRatio,
    ],
    events: FALGOM_EVENTS,
  },
  useCase: {
    componentPath: "falgom/taroDiversified/UseCase.vue",
  },
};

const arpUsEquities = {
  productId: "arp-us-equities",
  storeModule: "arpUsEquities",
  productName: "ARP US Equities",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.monthly,
    zoomButtons: [
      ZoomButton.sixMonths,
      ZoomButton.ytd,
      ZoomButton.threeYears,
      ZoomButton.fiveYears,
      ZoomButton.fullPeriod,
    ],
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.drawdowns,
      GenericProductCards.returnProfile,
    ],
    perfStats: [
      PerformanceStatistic.cumulativeReturn,
      PerformanceStatistic.annualizedReturn,
      PerformanceStatistic.annualizedVolatility,
      PerformanceStatistic.maximumDrawdown,
      PerformanceStatistic.sharpeRatio,
      PerformanceStatistic.skewness,
      PerformanceStatistic.kurtosis,
      PerformanceStatistic.sortinoRatio,
    ],
    events: FALGOM_EVENTS,
  },
};

const arpCommodities = {
  productId: "arp-commodities",
  storeModule: "arpCommodities",
  productName: "ARP Commodities",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.monthly,
    zoomButtons: [
      ZoomButton.sixMonths,
      ZoomButton.ytd,
      ZoomButton.threeYears,
      ZoomButton.fiveYears,
      ZoomButton.fullPeriod,
    ],
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.drawdowns,
      GenericProductCards.returnProfile,
    ],
    perfStats: [
      PerformanceStatistic.cumulativeReturn,
      PerformanceStatistic.annualizedReturn,
      PerformanceStatistic.annualizedVolatility,
      PerformanceStatistic.maximumDrawdown,
      PerformanceStatistic.sharpeRatio,
      PerformanceStatistic.skewness,
      PerformanceStatistic.kurtosis,
      PerformanceStatistic.sortinoRatio,
    ],
    events: FALGOM_EVENTS,
  },
};

export const FALGOM_PRODUCTS_DETAILS = [
  taroDiversified,
  arpUsEquities,
  arpCommodities,
];

export const FALGOM_PRODUCTS = FALGOM_PRODUCTS_DETAILS.map(
  (details) => details.storeModule
);
