/* File containing constants related to major events and their dates. */
import { i18n } from "@/main.js";

// 'end' date to null means that it is still ongoing.
export const EventDate = Object.freeze({
  financialCrisis: {
    getName: () => i18n.t("pages.products.events.financialCrisis"),
    start: "2008-01-16",
    end: "2010-01-01",
  },
  euroCrisis: {
    getName: () => i18n.t("pages.products.events.euroCrisis"),
    start: "2011-02-18",
    end: "2012-03-01",
  },
  december2018Crisis: {
    getName: () => i18n.t("pages.products.events.december2018Crisis"),
    start: "2018-10-01",
    end: "2019-04-01",
  },
  covid19Crisis: {
    getName: () => i18n.t("pages.products.events.covidCrisis"),
    start: "2019-12-01",
    end: "2020-12-01",
  },
  // Special event because Protech was open during the
  // event, and it's simpler to create a tailored event.
  covid19CrisisForHistoricalProtech: {
    getName: () => i18n.t("pages.products.events.covidCrisis"),
    start: "2019-12-01",
    end: "2020-07-31",
  },
  // Products inception date to their death.
  lstsInception: {
    getName: () => i18n.t("pages.products.events.lstsLive"),
    start: "2021-07-16",
    end: null,
  },
  protechInception: {
    getName: () => i18n.t("pages.products.events.protechLive"),
    start: "2020-07-31",
    end: null,
  },
  alvolaInception: {
    getName: () => i18n.t("pages.products.events.alvolaLive"),
    start: "2021-06-02",
    end: null,
  },
  convexusInception: {
    getName: () => i18n.t("pages.products.events.convexusLive"),
    start: "2022-05-05",
    end: null,
  },
  utiNewLuxuryInception: {
    getName: () => i18n.t("pages.products.events.sinceInception"),
    start: "2023-06-21",
    end: null,
  },
  ynnovationInception: {
    getName: () => i18n.t("pages.products.events.ynnovationLive"),
    start: "2023-09-21",
    end: null,
  },
  falgomInception: {
    getName: () => i18n.t("pages.products.events.sinceInception"),
    start: "2022-01-31",
    end: null,
  },
  // Special events for CALMGARD.
  calmgardPostFinancialCrisis: {
    getName: () =>
      i18n.t("pages.products.events.calmgard.calmgardPostFinancialCrisis"),
    // They asked to start on 2008-12-31, but their product starts only on 2009-01-05.
    start: "2009-01-05",
    end: "2009-02-28",
  },
  calmgardEuroCrisisOne: {
    getName: () =>
      i18n.t("pages.products.events.calmgard.calmgardEuroCrisisOne"),
    start: "2010-04-30",
    end: "2010-06-30",
  },
  calmgardEuroCrisisTwo: {
    getName: () =>
      i18n.t("pages.products.events.calmgard.calmgardEuroCrisisTwo"),
    start: "2011-04-30",
    end: "2011-09-30",
  },
  calmgardGreekAndChinaCrisis: {
    getName: () =>
      i18n.t("pages.products.events.calmgard.calmgardGreekAndChinaCrisis"),
    start: "2015-07-31",
    end: "2016-02-29",
  },
  calmgardInterestRateSpeculationOne: {
    getName: () =>
      i18n.t(
        "pages.products.events.calmgard.calmgardInterestRateSpeculationOne"
      ),
    start: "2018-09-30",
    end: "2018-12-31",
  },
  calmgardCovid19Crisis: {
    getName: () =>
      i18n.t("pages.products.events.calmgard.calmgardCovid19Crisis"),
    start: "2019-12-31",
    end: "2020-03-31",
  },
  calmgardUkraineWar: {
    getName: () => i18n.t("pages.products.events.calmgard.calmgardUkraineWar"),
    start: "2021-12-31",
    end: "2022-09-30",
  },
  calmgardInterestRateSpeculationTwo: {
    getName: () =>
      i18n.t(
        "pages.products.events.calmgard.calmgardInterestRateSpeculationTwo"
      ),
    start: "2023-07-31",
    end: "2023-10-31",
  },
  // Special events for Falgom.
  falgomGlobalFinancialCrisis: {
    getName: () =>
      i18n.t("pages.products.events.falgom.falgomGlobalFinancialCrisis"),
    start: "2008-01-31",
    end: "2009-03-31",
  },
  falgomRecessionEurope: {
    getName: () => i18n.t("pages.products.events.falgom.falgomRecessionEurope"),
    start: "2010-05-31",
    end: "2011-08-31",
  },
  falgomGrowthSlowdownChina: {
    getName: () =>
      i18n.t("pages.products.events.falgom.falgomGrowthSlowdownChina"),
    start: "2015-10-31",
    end: "2016-04-30",
  },
  falgomVolmageddon: {
    getName: () => i18n.t("pages.products.events.falgom.falgomVolmageddon"),
    start: "2018-08-31",
    end: "2019-03-31",
  },
  falgomCoronavirusPandemic: {
    getName: () =>
      i18n.t("pages.products.events.falgom.falgomCoronavirusPandemic"),
    start: "2019-10-31",
    end: "2020-03-31",
  },
  falgomFedQuantitativeTapering: {
    getName: () =>
      i18n.t("pages.products.events.falgom.falgomFedQuantitativeTapering"),
    start: "2021-11-30",
    end: "2022-12-31",
  },
});

export const GLOBAL_EVENTS = [
  EventDate.financialCrisis,
  EventDate.euroCrisis,
  EventDate.december2018Crisis,
  EventDate.covid19Crisis,
];

export const CALMGARD_EVENTS = [
  EventDate.calmgardPostFinancialCrisis,
  EventDate.calmgardEuroCrisisOne,
  EventDate.calmgardEuroCrisisTwo,
  EventDate.calmgardGreekAndChinaCrisis,
  EventDate.calmgardInterestRateSpeculationOne,
  EventDate.calmgardCovid19Crisis,
  EventDate.calmgardUkraineWar,
  EventDate.calmgardInterestRateSpeculationTwo,
];

export const FALGOM_EVENTS = [
  EventDate.falgomGlobalFinancialCrisis,
  EventDate.falgomRecessionEurope,
  EventDate.falgomGrowthSlowdownChina,
  EventDate.falgomVolmageddon,
  EventDate.falgomCoronavirusPandemic,
  EventDate.falgomFedQuantitativeTapering,
  EventDate.falgomInception,
];
