<template lang="html">
  <div
    ref="coninput"
    :style="styleLabel"
    :class="[
      `vs-input-${color}`,
      {
        isFocus: isFocus,
        'input-icon-validate-success': success,
        'input-icon-validate-danger': danger,
        'input-icon-validate-warning': warning,
        'is-label-placeholder': labelPlaceholder,
      },
    ]"
    class="vs-component vs-con-input-label vs-input"
  >
    <label
      v-if="labelPlaceholder ? false : label"
      class="vs-input--label"
      for=""
      @click="focusInput"
      >{{ label }}</label
    >
    <div class="vs-con-input">
      <editor-tinymce
        v-if="allowTinyMceRendering"
        ref="vsinput"
        :init="{
          menubar: false,
          plugins: plugins,
          toolbar: toolbar,
          font_size_formats: '6px 7px 8px 9px 10px 11px 12px 13px 14px 16px 18px 20px 22px 24px 30px 36px 48px',
          skin: false,
          content_css: false,
          language: $i18n.locale,
          paste_as_text: true
        }"
        inline
        :style="style"
        :autofocus="autofocus"
        :class="[
          size,
          {
            hasValue: value !== '',
            hasIcon: icon,
            'icon-after-input': iconAfter,
          },
        ]"
        :placeholder="null"
        :value="value"
        :type="$attrs.type ? $attrs.type : 'text'"
        class="vs-inputx vs-input--input overflow-y-auto cursor-text reset-ul-ol-style"
        v-on="listeners"
      />
      <transition name="placeholderx">
        <span
          v-if="isValue && (labelPlaceholder || $attrs.placeholder)"
          ref="spanplaceholder"
          :style="styleLabel"
          :class="[
            labelPlaceholder && size,
            size,
            {
              'vs-placeholder-label': labelPlaceholder,
            },
          ]"
          class="input-span-placeholder vs-input--placeholder"
          @click="focusInput"
        >
          {{ $attrs.placeholder || labelPlaceholder }}
        </span>
      </transition>

      <vs-icon
        v-if="icon"
        :class="{ 'icon-after': iconAfter, 'icon-no-border': iconNoBorder }"
        :icon-pack="iconPack"
        :icon="icon"
        class="icon-inputx notranslate vs-input--icon"
        @click="
          focusInput();
          $emit('icon-click');
        "
      >
      </vs-icon>

      <transition name="icon-validate">
        <span
          v-if="success || danger || warning"
          :class="{ 'icon-before': iconAfter }"
          class="input-icon-validate vs-input--icon-validate"
        >
          <vs-icon
            :class="{ 'icon-before': iconAfter }"
            :icon-pack="valIconPack"
            :icon="getIcon"
          ></vs-icon>
        </span>
      </transition>
    </div>

    <transition-group @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <div
        v-if="success"
        key="success"
        class="con-text-validation vs-input--text-validation"
      >
        <span
          class="span-text-validation span-text-validation-success vs-input--text-validation-span"
        >
          {{ successText }}
        </span>
      </div>
      <div
        v-else-if="danger"
        key="danger"
        class="con-text-validation span-text-validation-danger vs-input--text-validation-span"
      >
        <span class="span-text-validation">
          {{ dangerText }}
        </span>
      </div>
      <div
        v-else-if="warning"
        key="warning"
        class="con-text-validation span-text-validation-warning vs-input--text-validation-span"
      >
        <span class="span-text-validation">
          {{ warningText }}
        </span>
      </div>
      <div
        v-if="descriptionText"
        key="description"
        class="con-text-validation span-text-validation vs-input--text-validation-span"
      >
        <span class="span-text-validation">
          {{ descriptionText }}
        </span>
      </div>
    </transition-group>
  </div>
</template>

<script>
import _color from "@/assets/utils/color.js";

export default {
  name: "TinyMceInput",
  inheritAttrs: false,
  props: {
    value: {
      default: "",
      type: [String, Number],
    },
    labelPlaceholder: {
      default: null,
      type: [String, Number],
    },
    label: {
      default: null,
      type: [String, Number],
    },
    autofocus: {
      default: false,
      type: [Boolean, String],
    },
    icon: {
      default: null,
      type: String,
    },
    iconAfter: {
      default: false,
      type: [Boolean, String],
    },
    iconNoBorder: {
      default: false,
      type: Boolean,
    },
    iconPack: {
      default: "material-icons",
      type: String,
    },
    color: {
      default: "primary",
      type: String,
    },
    success: {
      default: false,
      type: Boolean,
    },
    danger: {
      default: false,
      type: Boolean,
    },
    warning: {
      default: false,
      type: Boolean,
    },
    successText: {
      default: null,
      type: String,
    },
    dangerText: {
      default: null,
      type: String,
    },
    warningText: {
      default: null,
      type: String,
    },
    descriptionText: {
      default: null,
      type: String,
    },
    size: {
      default: "normal",
      type: String,
    },
    valIconPack: {
      default: "material-icons",
      type: String,
    },
    valIconSuccess: {
      default: null,
      type: String,
    },
    valIconDanger: {
      default: null,
      type: String,
    },
    valIconWarning: {
      default: null,
      type: String,
    },
    rows: {
      default: 1,
      type: [Number, String],
    },
    maxRows: {
      default: 3,
      type: [Number, String],
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    editorWithTextAlignment: {
      default: true,
      type: Boolean,
    },
    editorWithList: {
      default: true,
      type: Boolean,
    },
  },
  inject: {
    elForm: {
      default: "",
    },
    elFormItem: {
      default: "",
    },
  },
  data: () => ({
    isFocus: false,
    allowTinyMceRendering: false,
  }),
  async mounted() {
    // For some unknown reason, the editor-tinymce component is "mounted" if we
    // do not wait for the nextTick. For example, the one in description won't
    // work, but in this case only for platform with one language, and this because
    // they are not using "vs-tabs" (c.f. GenericComponent editPrompt).
    // TODO how to correctly trigger the rendering of this element, without having to use nextTick
    await this.$nextTick();
    this.allowTinyMceRendering = true;
  },
  watch: {
    // The disbaled on the Editor object of TinyMce is broken, apparently using
    // a method 'setMode' instead of 'mode.set', hence we do it ourselves.
    disabled(newVal) {
      this.$refs["vsinput"].editor.mode.set(newVal ? "readonly" : "design");
    },
  },
  computed: {
    // The computation of the two following heights are coming from:
    // 1. a y-padding of 0.7 rem => 2 * 0.7 * the base font => 2 * 0.7 * 14 = 2 * 9.8
    // 2. a <p> tag being 21px because p.height => line height * base font => 1.5 * 14
    // TODO how to access those values easily? need to create scss variables?
    height() {
      return 9.8 * 2 + 21 * this.rows;
    },
    maxHeight() {
      return (
        9.8 * 2 + 21 * (this.maxRows > this.rows ? this.maxRows : this.rows)
      );
    },
    style() {
      return {
        border: `1px solid ${
          this.isFocus ? _color.getColor(this.color, 1) : "rgba(0, 0, 0,.2)"
        }`,
        "max-height": `${this.maxHeight}px`,
        "min-height": `${this.height}px`,
      };
    },
    plugins() {
      if (this.editorWithList) {
        return "link lists";
      }

      return "link";
    },
    toolbar() {
      return `bold italic underline | fontsize |${
        this.editorWithTextAlignment
          ? " alignleft aligncenter alignright alignjustify |"
          : ""
      } ${this.editorWithList ? " numlist bullist |" : ""} link`;
    },
    styleLabel() {
      return {
        color: this.isFocus ? _color.getColor(this.color, 1) : null,
      };
    },
    listeners() {
      return {
        ...this.$listeners,
        input: (htmlInput) => {
          this.$emit("input", htmlInput);
        },
        focus: (evt) => {
          this.$emit("focus", evt);
          this.changeFocus(true);
        },
        blur: (evt) => {
          this.$emit("blur", evt);
          this.changeFocus(false);
        },
        onFocusIn: () => {
          // When the focus is put in the input, the inline tiny mce will appear.
          // At this moment, we will check the height is more than a threshold, as it will
          // indicate if the inline menu is one one or two rows. Normally it should only
          // be on one row, but sometimes, because of how pixel sizes try to adapt when
          // the browser is on zoom, the menu appears on two rows. The issue with two
          // rows is that it hides part of the input, hence the user can't modify part
          // of the text
          // To fix it, we simpy take the current width (computed inside tiny mce),
          // and if we have two rows, we enlarge it a bit.
          // Note that it doesn't recompute if the inline menu width is already shown, and
          // you update the zoom. Bluring and focusing again the input will recompute
          // it though.
          // Note that if we multiple tiny mce input on the page, we can find the correct
          // inline menu by checking which one is displayed.
          const inlineMenu = Array.from(
            document.getElementsByClassName("tox-tinymce-inline")
          ).find((el) => el.style["display"] === "flex");
          const inlineMenuRect = inlineMenu.getBoundingClientRect();
          if (inlineMenuRect.height > 50) {
            inlineMenu.style["width"] = `${
              parseInt(inlineMenuRect.width) + 4
            }px`;
          }
        },
      };
    },
    isValue() {
      return this.labelPlaceholder ? true : !this.value;
    },
    getIcon() {
      return this.danger
        ? this.valIconDanger
        : this.warning
        ? this.valIconWarning
        : this.success
        ? this.valIconSuccess
        : "";
    },
  },
  methods: {
    // animation
    changeFocus(booleanx) {
      this.isFocus = booleanx;
    },
    beforeEnter(el) {
      el.style.height = 0;
    },
    enter(el, done) {
      let h = el.scrollHeight;
      el.style.height = h + "px";
      done();
    },
    leave: function (el) {
      el.style.height = 0 + "px";
    },
    focusInput() {
      this.$refs.vsinput.focus();
    },
  },
};
</script>
