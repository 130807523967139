import alquantConfig from "@/config/alquant";
import bancaCredinvestConfig from "@/config/banca-credinvest";
import bcfConfig from "@/config/bcf";
import biCapitalConfig from "@/config/bi-capital";
import calmgardConfig from "@/config/calmgard";
import cgeTechCareConfig from "@/config/cge-tech-care";
import chameleonConfig from "@/config/chameleon";
import citeGestionConfig from "@/config/cite-gestion";
import cronosConfig from "@/config/cronos";
import demoConfig from "@/config/demo";
import equinoxConfig from "@/config/equinox";
import falgomConfig from "@/config/falgom";
import finanzlabConfig from "@/config/finanzlab";
import forumCapitalConfig from "@/config/forum-capital";
import lakefieldConfig from "@/config/lakefield";
import miranteConfig from "@/config/mirante";
import msmConfig from "@/config/msm";
import oneSwissBankConfig from "@/config/one-swiss-bank";
import openfundsConfig from "@/config/openfunds";
import sixtConfig from "@/config/6t";
import testAutoConfig from "@/config/test-auto";
import pptConfig from "@/config/ppt";
import ssiConfig from "@/config/ssi";
import utiConfig from "@/config/uti";

/**
 *
 * @typedef {{
 *  mode: string,
 *  updateFrequency: string|undefined,
 *  componentPath: string|undefined,
 *  allowedRoles: Array<string>|undefined,
 *  allowLiveComparison: boolean|undefined,
 *  zoomButtons: Array<any>
 *  genericCardsToShow: Array<string>,
 *  perfStats: Array<string>,
 *  events: Array<{name: string, start: string, end: string}>|undefined,
 *  tabmenuText: string|undefined,
 *  allowLogScale: boolean|undefined,
 *  defaultLogScale: boolean|undefined,
 * }}
 * AnalysisDetail
 */

/**
 * TODO remove logoPath when factsheet v2 is released and v1 removed.
 * @typedef {{
 *  mode: string|undefined,
 *  allowedRoles: Array<string>|undefined,
 *  description: string|undefined,
 *  showBenchmark: boolean|undefined,
 *  perfStats: Array<string>,
 *  needLoginToSee: boolean|undefined,
 * }}
 * FactsheetDetail
 */

/**
 *
 * @typedef {{
 *  orgId: string|undefined,
 *  productId: string,
 *  lockedBehindLogin: boolean|undefined,
 *  hideWhenAskedInSettings: boolean|undefined,
 *  dev: boolean|undefined,
 *  storeModule: string,
 *  productName: string,
 *  productShortName: string|undefined,
 *  productColor: any,
 *  productShareClassRoot: string|undefined,
 *  isDefaultShareClass: boolean|undefined,
 *  oldDataEndDate: string|undefined,
 *  oldDataHighlightColor: string|undefined,
 *  showNextSubscriptionAndRedemption: boolean|undefined,
 *  availableLanguages: Array<string>|undefined,
 *  allowPriceDataModification: boolean|undefined,
 *  priceModificationUpdateGcpStorage: boolean|undefined,
 *  allowedRoles: Array<string>|undefined,
 *  timelineData: Array<{
 *    color: string,
 *    icon: string,
 *    title: string,
 *    desc: string,
 *    time: string,
 *  }>|undefined,
 *  tabmenuItems: Array<{url: string, name: string, slug: string}>|undefined,
 *  live: AnalysisDetail|undefined,
 *  historical: AnalysisDetail|undefined,
 *  internal: AnalysisDetail|undefined,
 *  useCase: {componentPath: string}|undefined,
 *  factsheet: FactsheetDetail|undefined,
 * }}
 * ProductDetail
 */

/**
 *
 * @typedef {{
 *    getTitle: () => string,
 *    subSections: Array<SubSectionDetail>
 *  }}
 * CustomSectionDetail
 */

/**
 *
 * @typedef {{
 *    navMenuItem: {
 *      url: string,
 *      getName: () => string,
 *      slug: string,
 *      icon: string|undefined,
 *      featured: boolean|undefined,
 *    },
 *    router: {
 *      path: string,
 *      name: string,
 *      component: string,
 *    }
 *  }}
 * SubSectionDetail
 */

/**
 *
 * @typedef {{
 *  knowledgeBase: boolean,
 *  faq: {faqs: () => Array<{id: number, question: string, ans: string}>},
 *  contact: boolean,
 * }}
 * HelpConfig
 */

const configMapping = {
  alquant: alquantConfig,
  "banca-credinvest": bancaCredinvestConfig,
  bcf: bcfConfig,
  "bi-capital": biCapitalConfig,
  calmgard: calmgardConfig,
  "cge-tech-care": cgeTechCareConfig,
  chameleon: chameleonConfig,
  "cite-gestion": citeGestionConfig,
  cronos: cronosConfig,
  demo: demoConfig,
  equinox: equinoxConfig,
  falgom: falgomConfig,
  finanzlab: finanzlabConfig,
  "forum-capital": forumCapitalConfig,
  lakefield: lakefieldConfig,
  mirante: miranteConfig,
  msm: msmConfig,
  "one-swiss-bank": oneSwissBankConfig,
  openfunds: openfundsConfig,
  "6t": sixtConfig,
  "test-auto": testAutoConfig,
  ppt: pptConfig,
  ssi: ssiConfig,
  uti: utiConfig,
};

/**
 * @type {{
 *  name: string,
 *  title: string|undefined,
 *  phone: string|undefined,
 *  email: string|undefined,
 *  img: string|undefined,
 * }}
 * ContactDetail
 */

/**
 *
 * @type {{
 *  orgName: string,
 *  showHomePageHeader: boolean,
 *  logoPath: string,
 *  logoExternalUrl: string | undefined,
 *  buttonExternalUrl: {url: string, getText: () => string} | undefined,
 *  allowedLoginMethods: Array<string>|undefined,
 *  hideLogoHomePage: boolean|undefined,
 *  hideAlquantLinkFooter: boolean|undefined,
 *  hideCopyrightFooter: boolean|undefined,
 *  background: {path: string, darkRatio: number|undefined, lightRatio: number|undefined},
 *  slogan: boolean|undefined,
 *  navBarLogoStyle: string|undefined,
 *  withProductsFilteringAbility: boolean|undefined,
 *  withProductsFilteringAbilityOnHome: boolean|undefined,
 *  numberOfProductsToShow: number|undefined,
 *  numberOfProductsToShowOnHome: number|undefined,
 *  products: Array<ProductDetail>,
 *  customSections: Array<CustomSectionDetail>,
 *  help: HelpConfig,
 *  possibleRoles: Array<string>|undefined,
 *  oldAlternativeUrlRedirection: Array<{oldUrl: string, newUrl: string}>|undefined,
 *  availableLanguages: Array<string>,
 *  langInUrl: boolean | undefined,
 *  allowedRoles: Array<string>|undefined,
 *  contactEmail: string | undefined,
 *  hideLoginRegisterButtons: boolean | undefined,
 *  followFeatureActivated: boolean | undefined,
 *  colorScheme: Array<string>|undefined
 * }}
 */
const config = configMapping[process.env.VUE_APP_ORG_ID];
export default config;
