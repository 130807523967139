import Vue from "vue";
import axios from "@/http/axios";

const state = {
  benchmarks: {},
};

const getters = {
  getBenchmark: (state) => (benchmarkId) => {
    if (!state.benchmarks) {
      return state.benchmarks;
    }

    return state.benchmarks[benchmarkId];
  },
  getAllBenchmarks: (state) => {
    return state.benchmarks;
  },
};

const mutations = {
  FETCH_BENCHMARK_SUCCESS: (state, { benchmarkId, data }) => {
    if (!state.benchmarks) {
      state.benchmarks = {};
    }

    Vue.set(state.benchmarks, benchmarkId, data);
  },
  FETCH_ALL_BENCHMARKS_SUCCESS: (state, { data }) => {
    if (!data) {
      state.benchmarks = data;
    } else {
      data.forEach((element) => {
        Vue.set(state.benchmarks, element["id_"], element);
      });
    }
  },
};

const actions = {
  async fetchBenchmark({ commit, state }, { benchmarkId }) {
    if (state.benchmarks && state.benchmarks[benchmarkId]) {
      // Don't update the state (by putting data to null) if we already have this
      // benchmark.
      commit("FETCH_BENCHMARK_SUCCESS", { benchmarkId, data: null });
      return;
    }
    try {
      const response = await axios.get("/api/benchmarks/" + benchmarkId);
      commit("FETCH_BENCHMARK_SUCCESS", { benchmarkId, data: response.data });
    } catch (error) {
      commit("FETCH_BENCHMARK_SUCCESS", {
        benchmarkId,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchAllBenchmarks({ commit }, { benchmarkIds }) {
    const params = {};
    if (benchmarkIds !== undefined) {
      params["benchmark_ids"] = benchmarkIds;
    }
    try {
      const response = await axios.get("/api/benchmarks/", {
        params: params,
      });
      commit("FETCH_ALL_BENCHMARKS_SUCCESS", { data: response.data });
    } catch (error) {
      commit("FETCH_ALL_BENCHMARKS_SUCCESS", {
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
