import { cloneDeep } from "lodash";
import { Role } from "@/acl/roles";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { PerformanceStatistic } from "@/assets/constants/perfStats";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];
const INTERNAL_ALLOWED_ROLES = [Role.admin, Role.superAdmin];

const CITE_GESTION_PERF_STATS = [
  PerformanceStatistic.cumulativeReturn,
  PerformanceStatistic.annualizedReturn,
  PerformanceStatistic.annualizedVolatility,
  PerformanceStatistic.maximumDrawdown,
  PerformanceStatistic.sharpeRatio,
];

const citeGestionChfDynamicStrategy = {
  productId: "cite-gestion-chf-dynamic-strategy",
  storeModule: "citeGestionChfDynamicStrategy",
  productName: "Cité Gestion CHF Dynamic Strategy",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: CITE_GESTION_PERF_STATS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const citeGestionUsdDynamicStrategy = {
  productId: "cite-gestion-usd-dynamic-strategy",
  storeModule: "citeGestionUsdDynamicStrategy",
  productName: "Cité Gestion USD Dynamic Strategy",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: CITE_GESTION_PERF_STATS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const citeGestionEurDynamicStrategy = {
  productId: "cite-gestion-eur-dynamic-strategy",
  storeModule: "citeGestionEurDynamicStrategy",
  productName: "Cité Gestion EUR Dynamic Strategy",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: CITE_GESTION_PERF_STATS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// CHF ETF Model Portfolio
const citeGestionChfBalModelPortfolio = {
  productId: "cite-gestion-chf-bal-model-portfolio",
  storeModule: "citeGestionChfBalModelPortfolio",
  productName: "CHF Balanced ETF Model Portfolio",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: CITE_GESTION_PERF_STATS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const citeGestionChfConsModelPortfolio = cloneDeep(
  citeGestionChfBalModelPortfolio
);
citeGestionChfConsModelPortfolio.productId =
  "cite-gestion-chf-cons-model-portfolio";
citeGestionChfConsModelPortfolio.storeModule =
  "citeGestionChfConsModelPortfolio";
citeGestionChfConsModelPortfolio.productName =
  "CHF Conservative ETF Model Portfolio";

const citeGestionChfGroModelPortfolio = cloneDeep(
  citeGestionChfBalModelPortfolio
);
citeGestionChfGroModelPortfolio.productId =
  "cite-gestion-chf-gro-model-portfolio";
citeGestionChfGroModelPortfolio.storeModule = "citeGestionChfGroModelPortfolio";
citeGestionChfGroModelPortfolio.productName = "CHF Growth ETF Model Portfolio";

// EUR ETF Model Portfolio
const citeGestionEurBalModelPortfolio = {
  productId: "cite-gestion-eur-bal-model-portfolio",
  storeModule: "citeGestionEurBalModelPortfolio",
  productName: "EUR Balanced ETF Model Portfolio",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: CITE_GESTION_PERF_STATS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const citeGestionEurConsModelPortfolio = cloneDeep(
  citeGestionEurBalModelPortfolio
);
citeGestionEurConsModelPortfolio.productId =
  "cite-gestion-eur-cons-model-portfolio";
citeGestionEurConsModelPortfolio.storeModule =
  "citeGestionEurConsModelPortfolio";
citeGestionEurConsModelPortfolio.productName =
  "EUR Conservative ETF Model Portfolio";

const citeGestionEurGroModelPortfolio = cloneDeep(
  citeGestionEurBalModelPortfolio
);
citeGestionEurGroModelPortfolio.productId =
  "cite-gestion-eur-gro-model-portfolio";
citeGestionEurGroModelPortfolio.storeModule = "citeGestionEurGroModelPortfolio";
citeGestionEurGroModelPortfolio.productName = "EUR Growth ETF Model Portfolio";

// USD ETF Model Portfolio
const citeGestionUsdBalModelPortfolio = {
  productId: "cite-gestion-usd-bal-model-portfolio",
  storeModule: "citeGestionUsdBalModelPortfolio",
  productName: "USD Balanced ETF Model Portfolio",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: CITE_GESTION_PERF_STATS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const citeGestionUsdConsModelPortfolio = cloneDeep(
  citeGestionUsdBalModelPortfolio
);
citeGestionUsdConsModelPortfolio.productId =
  "cite-gestion-usd-cons-model-portfolio";
citeGestionUsdConsModelPortfolio.storeModule =
  "citeGestionUsdConsModelPortfolio";
citeGestionUsdConsModelPortfolio.productName =
  "USD Conservative ETF Model Portfolio";

const citeGestionUsdGroModelPortfolio = cloneDeep(
  citeGestionUsdBalModelPortfolio
);
citeGestionUsdGroModelPortfolio.productId =
  "cite-gestion-usd-gro-model-portfolio";
citeGestionUsdGroModelPortfolio.storeModule = "citeGestionUsdGroModelPortfolio";
citeGestionUsdGroModelPortfolio.productName = "USD Growth ETF Model Portfolio";

export const CITE_GESTION_PRODUCTS_DETAILS = [
  citeGestionChfDynamicStrategy,
  citeGestionUsdDynamicStrategy,
  citeGestionEurDynamicStrategy,
  // Model portfolios
  citeGestionChfBalModelPortfolio,
  citeGestionChfConsModelPortfolio,
  citeGestionChfGroModelPortfolio,
  citeGestionEurBalModelPortfolio,
  citeGestionEurConsModelPortfolio,
  citeGestionEurGroModelPortfolio,
  citeGestionUsdBalModelPortfolio,
  citeGestionUsdConsModelPortfolio,
  citeGestionUsdGroModelPortfolio,
];

export const CITE_GESTION_PRODUCTS = CITE_GESTION_PRODUCTS_DETAILS.map(
  (details) => details.storeModule
);
