import components from "./components.js";
import pages from "./pages.js";
import wlps from "./wlps.js";

export default {
  apply: "Apply",
  asOf: (ctx) => `as of ${ctx.named("date")}`,
  cancel: "Cancel",
  contactUs: "Contact us",
  edit: "Edit",
  error: "Error",
  from: "From",
  ok: "Ok",
  loading: "Loading...",
  logIn: "Log in",
  logOut: "Log out",
  no: "No",
  others: "Others",
  register: "Register",
  save: "Save",
  saving: "Saving...",
  serverError:
    "An error occured in our server. A report has been sent to our team and we will fix it as soon as possible. Don't hesitate to contact us if the issue persist.",
  success: "Success",
  to: "To",
  warning: "Warning",
  yes: "Yes",
  investorProfileTypes: {
    institutional: "Institutional investor",
    professional: "Professional investor",
    retail: "Retail investor",
  },
  layouts: {
    main: {
      dev: "Dev",
      devTooltip: "This product is in development",
      admin: {
        listUsers: "List of users",
        platformSettings: "Platform settings",
        products: "Products",
        sectionName: "Admin",
      },
      help: {
        contact: "Contact",
        faq: "FAQ",
        knowledgeBase: "Knowledge Base",
        sectionName: "Help",
      },
      products: {
        analysis: "Analysis",
        documents: "Documents",
        factsheet: "Factsheet",
        overview: "Overview",
        sectionName: "Products",
        useCase: "Use Case",
      },
    },
    fullPage: {
      backHome: "Back to Home",
    },
    profileDropDown: {
      settings: "Settings",
      dialog: {
        adminAsRegularOnMobile: {
          text: "The administrative features cannot be accessed on mobile devices. To fully experience the platform, please utilize a desktop computer. The disabled admin features include (but are not limited to): editing elements in the Overview, editing the Factsheet, uploading or deleting documents, and accessing the admin section.",
          title: "Desktop computer required for admin features",
        },
      },
    },
    theFooter: {
      allRightReserved: "All rights reserved",
      copyright: "Copyright",
      ombudsman: "Ombudsman",
      privacyPolicy: "Privacy policy",
      termsOfUse: "Terms of use",
    },
    verticalNavMenu: {
      errorLoading: "Error when loading menu",
    },
    verticalNavMenuGroup: {
      search: "Search",
      showMore: "Show more",
    },
  },
  perfStats: {
    alpha: "Alpha",
    annualizedReturn: "Annualized return",
    annualizedVolatility: "Annualized volatility",
    averageMonthlyReturns: "Average monthly returns",
    averageNegativeMonthlyReturns: "Average negative monthly returns",
    averagePositiveMonthlyReturns: "Average positive monthly returns",
    beta: "Beta",
    calmarRatio: "Calmar ratio",
    correlation: "Correlation",
    cumulativeReturn: "Cumulative return",
    informationRatio: "Information ratio",
    kurtosis: "Kurtosis",
    m2RiskAdjustedPerfMonthly: "M2 measure",
    maxConsecutiveNegativeMonthlyReturns:
      "Max consecutive negative monthly returns",
    maxConsecutivePositiveMonthlyReturns:
      "Max consecutive positive monthly returns",
    maxNegativeMonthlyReturn: "Max negative monthly return",
    maxPositiveMonthlyReturn: "Max positive monthly return",
    maximumDrawdown: "Maximum drawdown",
    omegaRatio: "Omega ratio",
    ratioOfNegativeMonthlyReturns: "Ratio of negative monthly returns",
    ratioOfPositiveMonthlyReturns: "Ratio of positive monthly returns",
    returnToVolatilityRatio: "Return to volatility ratio",
    sharpeRatio: "Sharpe ratio",
    skewness: "Skewness",
    sortinoRatio: "Sortino ratio",
    trackingError: "Tracking error",
    treynorRatio: "Treynor ratio",
    valueAtRiskOnePercentOneMonth: "Value at Risk (VaR) 1% over 1 month",
    valueAtRiskFivePercentOneMonth: "Value at Risk (VaR) 5% over 1 month",
  },
  validations: {
    afterDate: (ctx) =>
      ctx.named("nDaysApart") == 0
        ? `Date must be after ${ctx.named("minDate")} (included)`
        : `Date must be at least ${ctx.named(
            "nDaysApart"
          )} days after ${ctx.named("minDate")}`,
    beforeDate: (ctx) =>
      ctx.named("nDaysApart") == 0
        ? `Date must be before ${ctx.named("maxDate")} (included)`
        : `Date must be at least ${ctx.named(
            "nDaysApart"
          )} days before ${ctx.named("maxDate")}`,
    confirmed: "The field doesn't match with previous one.",
    dateFormat: "Date must be in the format YYYY-MM-DD.",
    email: "The email is not valid.",
    maxFileSize: (ctx) =>
      `The file is bigger than the maximum authorized size (${(
        ctx.named("maxSizeBytes") /
        1000 /
        1000
      ).toFixed(0)} MB).`,
    maxChar: (ctx) =>
      `The field can have no more than ${ctx.named("length")} characters.`,
    minChar: (ctx) =>
      `The field needs at least ${ctx.named("length")} characters.`,
    minValue: (ctx) =>
      `The value must be greater or equal to ${ctx.named("min")}.`,
    phoneNumber: "This field is not a valid phone number.",
    required: "This field is required.",
    roleFormat: 'The name of the role cannot contain the character ":".',
    sum: (ctx) =>
      `The expected sum is ${ctx.named("expectedValue")} (current: ${ctx.named(
        "currentValue"
      )}).`,
  },
  wlps,
  pages,
  components,
};
