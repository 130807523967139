import { Role } from "@/acl/roles";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import {
  STANDARD_PERF_STATS_WITH_BENCHMARK,
  STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
} from "@/assets/constants/perfStats";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];
const INTERNAL_ALLOWED_ROLES = [Role.admin, Role.superAdmin];

const abrSystematicLongVolatilityInstitutionalUsd = {
  productId: "abr-systematic-long-volatility-institutional-usd",
  storeModule: "abrSystematicLongVolatilityInstitutionalUsd",
  productName: "ABR Dynamic Blend Equity & Volatility Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const abrSystematicShortVolatilityGUsd = {
  productId: "abr-systematic-short-volatility-g-usd",
  storeModule: "abrSystematicShortVolatilityGUsd",
  productName: "ABR Enhanced Short Volatility Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const abrDynamicVolatilityStrategy75Long25ShortInvestorKClassUsd = {
  productId:
    "abr-dynamic-volatility-strategy-75-long-25-short-investor-k-class-usd",
  storeModule: "abrDynamicVolatilityStrategy75Long25ShortInvestorKClassUsd",
  productName: "ABR 75/25 Volatility Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const apuanoEquityChinaLongShortInstitutionalAUsd = {
  productId: "apuano-equity-china-long-short-institutional-a-usd",
  storeModule: "apuanoEquityChinaLongShortInstitutionalAUsd",
  productName: "Apuano Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const icgNaturalResourcesIndustrialMetalsUsdC = {
  productId: "icg-natural-resources-industrial-metals-usd-c",
  storeModule: "icgNaturalResourcesIndustrialMetalsUsdC",
  productName: "ICG Industrial Metals Champions Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const icgNaturalResourcesEnergyUsdA1 = {
  productId: "icg-natural-resources-energy-usd-a1",
  storeModule: "icgNaturalResourcesEnergyUsdA1",
  productName: "ICG Energy Champions Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const icgNaturalResourcesPreciousMetalsUsdA = {
  productId: "icg-natural-resources-precious-metals-usd-a",
  storeModule: "icgNaturalResourcesPreciousMetalsUsdA",
  productName: "ICG Precious Metals Champions Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const norronEsgNordicLongOnlySustainableRcSek = {
  productId: "norron-esg-nordic-long-only-sustainable-rc-sek",
  storeModule: "norronEsgNordicLongOnlySustainableRcSek",
  productName: "Norron Sustainable Equity",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const norronEsgNordicLongShortHicEur = {
  productId: "norron-esg-nordic-long-short-hic-eur",
  storeModule: "norronEsgNordicLongShortHicEur",
  productName: "Norron Select",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const openfundsDynamicVolatilityStrategy75Long25ShortInstitutionalUsd = {
  productId:
    "openfunds-dynamic-volatility-strategy-75-long-25-short-institutional-usd",
  storeModule:
    "openfundsDynamicVolatilityStrategy75Long25ShortInstitutionalUsd",
  productName: "Roca Enhanced Volatility Strategies Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const osmosisEsgGlobalEquitiesUsdAcc = {
  productId: "osmosis-esg-global-equities-usd-acc",
  storeModule: "osmosisEsgGlobalEquitiesUsdAcc",
  productName: "Osmosis Resource Efficient Core Equity Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const osmosisEsgGlobalEquitiesExFossilFuels = {
  productId: "osmosis-esg-global-equities-ex-fossil-fuels",
  storeModule: "osmosisEsgGlobalEquitiesExFossilFuels",
  productName: "Osmosis Resource Efficient Core Equity (ex-fossil fuels) Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const osmosisEsgEuropeanEquitiesEurC = {
  productId: "osmosis-esg-european-equities-eur-c",
  storeModule: "osmosisEsgEuropeanEquitiesEurC",
  productName: "Osmosis Resource Efficient European Equities Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const prestigeCommercialFinanceOpportunitiesIClassGbp = {
  productId: "prestige-commercial-finance-opportunities-i-class-gbp",
  storeModule: "prestigeCommercialFinanceOpportunitiesIClassGbp",
  productName: "Commercial Finance Opportunities",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.monthly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const prestigePrestigeAlternativeFinanceIClassGbp = {
  productId: "prestige-prestige-alternative-finance-i-class-gbp",
  storeModule: "prestigePrestigeAlternativeFinanceIClassGbp",
  productName: "Prestige Alternative Finance",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.monthly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const prestigePrimeAlternativeFinanceIClassGbp = {
  productId: "prestige-prime-alternative-finance-i-class-gbp",
  storeModule: "prestigePrimeAlternativeFinanceIClassGbp",
  productName: "Prime Alternative Finance",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const prestigePremiumAlziraeaIClassGbp = {
  productId: "prestige-premium-alziraea-i-class-gbp",
  storeModule: "prestigePremiumAlziraeaIClassGbp",
  productName: "Premium Alziraea Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const tundraFrontierAndEmergingMarketsLongOnlyFEur = {
  productId: "tundra-frontier-and-emerging-markets-long-only-f-eur",
  storeModule: "tundraFrontierAndEmergingMarketsLongOnlyFEur",
  productName: "Tundra Sustainable Frontier",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const gamaFundsGlobalBondOpportunitiesFUsdAcc = {
  productId: "gama-funds-global-bond-opportunities-f-usd-acc",
  storeModule: "gamaFundsGlobalBondOpportunitiesFUsdAcc",
  productName: "GAMA Funds - Global Bond Opportunities",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const gamaFundsGlobalShortDatedOpportunitiesFUsdAcc = {
  productId: "gama-funds-global-short-dated-opportunities-f-usd-acc",
  storeModule: "gamaFundsGlobalShortDatedOpportunitiesFUsdAcc",
  productName: "GAMA Funds - Global Short-Dated Opportunities",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const digitalAssetsMomentumFundBUsd = {
  productId: "digital-assets-momentum-fund-b-usd",
  storeModule: "digitalAssetsMomentumFundBUsd",
  productName: "Digital Assets Momentum Fund",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
    allowLogScale: false,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

export const OPENFUNDS_PRODUCTS_DETAILS = [
  abrSystematicLongVolatilityInstitutionalUsd,
  abrSystematicShortVolatilityGUsd,
  abrDynamicVolatilityStrategy75Long25ShortInvestorKClassUsd,
  apuanoEquityChinaLongShortInstitutionalAUsd,
  icgNaturalResourcesIndustrialMetalsUsdC,
  icgNaturalResourcesEnergyUsdA1,
  icgNaturalResourcesPreciousMetalsUsdA,
  norronEsgNordicLongOnlySustainableRcSek,
  norronEsgNordicLongShortHicEur,
  openfundsDynamicVolatilityStrategy75Long25ShortInstitutionalUsd,
  osmosisEsgGlobalEquitiesUsdAcc,
  osmosisEsgGlobalEquitiesExFossilFuels,
  osmosisEsgEuropeanEquitiesEurC,
  prestigeCommercialFinanceOpportunitiesIClassGbp,
  prestigePrestigeAlternativeFinanceIClassGbp,
  prestigePrimeAlternativeFinanceIClassGbp,
  prestigePremiumAlziraeaIClassGbp,
  tundraFrontierAndEmergingMarketsLongOnlyFEur,
  gamaFundsGlobalBondOpportunitiesFUsdAcc,
  gamaFundsGlobalShortDatedOpportunitiesFUsdAcc,
  digitalAssetsMomentumFundBUsd,
];

export const OPENFUNDS_PRODUCTS = OPENFUNDS_PRODUCTS_DETAILS.map(
  (details) => details.storeModule
);
